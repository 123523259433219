import React from "react"
import { Link, graphql } from "gatsby"
import Parser from "html-react-parser"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import SidebarContent from "components/sidebarContent"
import LinkBoxes from "components/footer/LinkBoxes"
import { getUrlPath } from "utils/getUrlPath"
import sortBy from "utils/sortBy"

export default function taxonomyMember(props) {
  const {
    data: {
      wpMemberType: { uri, name, ccdArchivePage },
      allWpMember,
      allWpCountry
    }
  } = props

  const renderContent = () => {
    if (ccdArchivePage && ccdArchivePage.stateProvince) {
      return ccdArchivePage.stateProvince.map((o, i) => {
        return (
          <Section key={i} container justify="space-between">
            {o.city && (
              <>
                {o.stateProvinceName && <SectionHeadline variant="h2" children={o.stateProvinceName} />}
                {o.city.map((p, j) => {
                  let list = p.ccdList

                  if (list) {
                    // flatten list
                    list = list.map((o) => (o = o.ccd)).filter((o) => !!o)
                    // sortBy(list, "title", "ASC")
                  }

                  return (
                    <Grid key={`${i}-${j}`} container justify="space-between">
                      {list && (
                        <>
                          {p.cityName && <SectionHeadline variant="h4" children={p.cityName} />}
                          <SectionDivider />
                          {list.map((q) => {
                            return (
                              <MemberContainer key={q.id} to={getUrlPath(q.uri)}>
                                <ImageContainer>
                                  {q.Members?.smallMemberLogo?.sourceUrl && (
                                    <img src={q.Members?.smallMemberLogo?.sourceUrl} alt="Logo" />
                                  )}
                                </ImageContainer>

                                <Title children={Parser(q.title)} component="h3" variant="h6" />
                              </MemberContainer>
                            )
                          })}

                          <MemberContainer to={"/"} style={{ opacity: 0, margin: 0, pointerEvents: "none" }} />
                          <MemberContainer to={"/"} style={{ opacity: 0, margin: 0, pointerEvents: "none" }} />
                        </>
                      )}
                    </Grid>
                  )
                })}
              </>
            )}
          </Section>
        )
      })
    } else {
      const membersWithoutCountry = allWpMember?.nodes.filter((p) => p.countries.nodes.length === 0)

      membersWithoutCountry.length > 0 && sortBy(membersWithoutCountry, "title", "ASC")

      return (
        <>
          {membersWithoutCountry.length > 0 && (
            <Section container justify="space-between">
              {membersWithoutCountry.map((o) => {
                return (
                  <MemberContainer key={o.id} to={getUrlPath(o.uri)}>
                    <ImageContainer>
                      {o.Members?.smallMemberLogo?.sourceUrl && (
                        <img src={o.Members?.smallMemberLogo?.sourceUrl} alt="Logo" />
                      )}
                    </ImageContainer>

                    <Title children={Parser(o.title)} component="h3" variant="h6" />
                  </MemberContainer>
                )
              })}

              {/* <MemberContainer to={"/"} style={{ opacity: 0, margin: 0, pointerEvents: "none" }} />
              <MemberContainer to={"/"} style={{ opacity: 0, margin: 0, pointerEvents: "none" }} /> */}
            </Section>
          )}

          {allWpCountry?.nodes.map((o) => {
            const membersPerCountry = allWpMember?.nodes.filter((p) => p.countries.nodes.find((q) => q.slug === o.slug))

            membersPerCountry.length > 0 && sortBy(membersPerCountry, "title", "ASC")

            return (
              membersPerCountry.length > 0 && (
                <Section key={o.slug} container justify="space-between">
                  <SectionHeadline variant="h3" children={o.name} />

                  {membersPerCountry.map((p) => {
                    return (
                      <MemberContainer key={p.id} to={getUrlPath(p.uri)}>
                        <ImageContainer>
                          {p.Members?.smallMemberLogo?.sourceUrl && (
                            <img src={p.Members?.smallMemberLogo?.sourceUrl} alt="Logo" />
                          )}
                        </ImageContainer>

                        <Title children={Parser(p.title)} component="h3" variant="h6" />
                      </MemberContainer>
                    )
                  })}

                  {/* <MemberContainer to={"/"} style={{ opacity: 0, pointerEvents: "none" }} />
                  <MemberContainer to={"/"} style={{ opacity: 0, pointerEvents: "none" }} /> */}
                </Section>
              )
            )
          })}
        </>
      )
    }
  }

  return (
    <>
      <SidebarContent image={null} title={name} slug={getUrlPath(uri)}>
        {renderContent()}
      </SidebarContent>

      <LinkBoxes />
    </>
  )
}

const MemberContainer = styled(Link)`
  width: 100%;
  margin-bottom: 30px;
  text-decoration: none;

  @media (min-width: 640px) {
    width: calc(50% - 10px);
  }

  @media (min-width: 960px) {
    width: calc(33.3333% - 10px);
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  max-height: 100%;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const Title = styled(Typography)`
  text-align: center;
`

const Section = styled(Grid)`
  margin-bottom: 30px;
`

const SectionHeadline = styled(Typography)`
  width: 100%;
  padding-bottom: 10px;
`

const SectionDivider = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
`

export const CollectionQuery = graphql`
  query TaxonomyMember($slug: String!) {
    wpMemberType(slug: { eq: $slug }) {
      uri
      name
      ccdArchivePage {
        stateProvince {
          stateProvinceName
          city {
            cityName
            ccdList {
              ccd {
                __typename
                ... on WpMember {
                  id
                  title
                  uri
                  Members {
                    smallMemberLogo {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpMember(
      filter: { memberTypes: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        id
        title
        uri
        countries {
          nodes {
            slug
          }
        }
        Members {
          smallMemberLogo {
            sourceUrl
          }
        }
      }
    }
    allWpCountry {
      nodes {
        slug
        name
      }
    }
  }
`
